import React, { useState } from "react"
import axios from "axios"
import {
  Alert,
  AlertTitle,
  Box,
  Stack,
  Grid,
  MenuItem,
  FormHelperText,
} from "@mui/material"
import { Button, Input, Select, colors } from "@parallel-domain/pd-theme"
import { useForm, Controller } from "react-hook-form"

const WaitlistForm = props => {
  const { buttonText } = props

  const [status, setStatus] = useState<"success" | "error" | "">("")
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      jobTitle: "",
      message: "",
      category: "",
      source: "",
      sourceOther: "",
    },
  })

  const handleSubmitForm = async formData => {
    const {
      firstName,
      lastName,
      email,
      company,
      jobTitle,
      message,
      category,
      source,
      sourceOther,
    } = formData

    const data = {
      input_1: firstName,
      input_3: lastName,
      input_4: email,
      input_5: jobTitle,
      input_6: company,
      input_7: message,
      input_10: category,
      input_11: "Yes",
      input_12: source === "Other" ? sourceOther : source,
    }

    // https://docs.gravityforms.com/rest-api-v2/#post-forms-form-id-submissions
    const formId = 5
    const endpoint =
      process.env.GATSBY_BACKEND_WEBSITE +
      "/wp-json/gf/v2/forms/" +
      formId +
      "/submissions"

    try {
      setLoading(true)

      await axios.post(endpoint, data)

      setStatus("success")
    } catch (e) {
      setStatus("error")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      {status === "success" ? (
        <Alert
          id="waitlist-form-success"
          severity="success"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Thank you for your message</AlertTitle>
          Someone from our team will reach out soon.
        </Alert>
      ) : (
        <form
          method="post"
          noValidate
          onSubmit={e => {
            handleSubmit(handleSubmitForm)(e)
          }}
          style={{ marginBottom: 0 }}
        >
          <Stack spacing={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "Please enter your first name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="First name*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Please enter your last name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Last name*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Please enter your email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email",
                    },
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Company email*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="email"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="jobTitle"
                  control={control}
                  rules={{
                    required: "Please enter your job title",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Job title*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="company"
                  control={control}
                  rules={{
                    required: "Please enter a company name",
                  }}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Company name*"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="source"
                  control={control}
                  rules={{
                    required: "Please enter where you heard about us",
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Stack spacing={1}>
                      <Select
                        value={value}
                        displayEmpty
                        renderValue={(selected: string) => {
                          if (selected === "") {
                            return (
                              <span style={{ color: colors.text[700] }}>
                                How do you hear about us?
                              </span>
                            )
                          }

                          return selected
                        }}
                        onChange={onChange}
                      >
                        {[
                          "Email",
                          "LinkedIn",
                          "X",
                          "Conference",
                          "News",
                          "Article",
                          "Other",
                        ].map(category => {
                          return (
                            <MenuItem
                              key={category}
                              value={category}
                              sx={{
                                px: 2,
                                "&.Mui-selected": {
                                  backgroundColor: `${colors.secondary[600]} !important`,
                                },
                              }}
                            >
                              {category}
                            </MenuItem>
                          )
                        })}
                      </Select>

                      {error && (
                        <FormHelperText sx={{ color: colors.error }}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </Stack>
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: watch("source") === "Other" ? "block" : "none",
                }}
              >
                <Controller
                  name="sourceOther"
                  control={control}
                  {...(watch("source") === "Other" && {
                    rules: {
                      required: "Please enter where else you heard about us",
                    },
                  })}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Where else did you hear about us?"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="message"
                  control={control}
                  {...(watch("source") === "Other" && {
                    rules: { required: "Please enter a message" },
                  })}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Input
                      placeholder="Use case* Please describe your use-case in as much detail as you can. Diagnostic, training, testing, validation, data generation, pd.replicas, etc."
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                      rows={6}
                      multiline
                      sx={{ ".MuiOutlinedInput-root": { p: 0 } }}
                    />
                  )}
                />
                <FormHelperText color="error"></FormHelperText>
              </Grid>
            </Grid>

            {status === "error" && (
              <Alert severity="error" sx={{ background: "#d32f2f" }}>
                An error occurred while sending the information. Please try
                again later.
              </Alert>
            )}

            <Button
              type="submit"
              variant="outlined"
              color="primary"
              loading={loading}
              fullWidth
            >
              {buttonText}
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  )
}

export default WaitlistForm
